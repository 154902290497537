import * as React from "react";
import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout";

const NotFound = () => (
  <Layout>
    <div style={{ textAlign: `center` }}>
      <h1>404</h1>
      <p>Sayfa Bulunamadi.</p>
    </div>
  </Layout>
);

export default NotFound;
